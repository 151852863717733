import api from '../Services/axios';


export const BuscaDadosEmpresa_By_user_id = async ( id : string ) =>{

    const { data : res } = await api.get(`/colaboradorempresa/${id}`, {headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")}});
    

    if(res){
        try{

            return res

        }catch(error){
                return "Falha ao listar Empresas tente mais tarde."
            }
    }else{
        console.log("Nenhum dados retornado: ", res.colaborador_data)
    }

    return undefined;
    
}