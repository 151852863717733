import FaixaetariaIdade  from "../componentes-dash/faixaetariaIdade";
import HistoricoVisitas from "../componentes-dash/historicoVisitas";
import PerfilGenero from "../componentes-dash/perfilGenero";
import Cidades from "../componentes-dash/estados";
import Estados from "../componentes-dash/cidades"
import Graph1 from "../componentes-dash/graph1";
import { IoMailOutline, IoPrintOutline, IoFileTrayOutline, 
    IoChevronDownOutline, IoArrowDownOutline, IoCheckmark, 
    IoReturnDownBack, IoBusOutline, IoCheckmarkOutline, 
    IoCloseOutline } from 'react-icons/io5';


export const Visitantes: React.FC = () => {

    return (    
        <div className="content content-fixed" style={{maxWidth: "80%", margin: "auto"}}>
            <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                            <li className="breadcrumb-item"><a href="/visitantes">Dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Perfil do visitante</li>
                        </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Bem vindo ao painel de controle Sllips</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-white btn-uppercase">
                        <IoMailOutline className="wd-10 mg-r-5" /> Email
                        </button>
                        <button className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                        <IoPrintOutline className="wd-10 mg-r-5" /> Print
                        </button>
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <IoFileTrayOutline className="wd-10 mg-r-5" /> Generate Report
                        </button>
                    </div>
                    </div>

                    <div className="row row-xs">
                    
                    <PerfilGenero />

                    <FaixaetariaIdade />

                    <Estados />

                    <Cidades />
                    
                    <HistoricoVisitas />

                    {/* <Graph1 /> */}


                </div>
            </div>
        </div>
            
    )
}