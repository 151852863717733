// src/components/Layout.tsx
import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
}

const Main: React.FC<LayoutProps> = ({ children }) => {
  return (
      <main style={{ flex: 1, padding: '20px' }}>
        {children}
      </main>  
  );
};

export default Main;
