import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import api from '../../../Services/axios';



// Registrando componentes do Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

  const FaixaetariaIdade: React.FC = () => {

    const [apiData, setData] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState(null)

    useEffect(() => {
      const fetchData = async () =>{
        try {
          setLoading(true);
          const value = await api.get("/dashboard/faixa-etaria", 
            { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }}
          )
          setLoading(false);
          setData(value.data);
        } catch (error) {
          setLoading(false);
        }
      }

      fetchData();
    }, [])

    const data = {
      labels: [],
      datasets: [
        {
          label: 'Faixa',
          data: apiData, // Valores associados às categorias
          backgroundColor: ['#75bcff', '#7ee5e5', '#f77eb9', '#fdbd88'], // Cores das fatias
          hoverOffset: 4, // Efeito de destaque ao passar o mouse
        },
      ],
    };

    function calcularPorcentagens(lista: number[]): number[] {
      const somaTotal = lista.reduce((total, num) => total + num, 0);
      
      return lista.map((valor) => (somaTotal > 0 ? (valor / somaTotal) * 100 : 0));
    }

    const porcentagens: number[] = calcularPorcentagens(apiData);

    return (    
        <div className="col-md-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-0">
            <div className="card">
              <div className="card-header">
                <h6 className="mg-b-0">Faixa etária de Idade</h6>
              </div>
              <div className="card-body pd-lg-25">
                <div style={{ width: '100%', height: '188px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Doughnut data={data} />
                </div>
              </div>
              <div className="card-footer pd-20">
                <div className="row">
                  <div className="col-6">
                    <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-5">0 a 17 anos</p>
                    <div className="d-flex align-items-center">
                      <div className="wd-10 ht-10 rounded-circle bg-pink mg-r-5"></div>
                      <h5 className="tx-normal tx-rubik mg-b-0">{ apiData[0] }<small className="tx-color-04 mg-l-10">{porcentagens[0]}%</small></h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5"> 18 a 34 anos</p>
                    <div className="d-flex align-items-center">
                      <div className="wd-10 ht-10 rounded-circle bg-primary mg-r-5"></div>
                      <h5 className="tx-normal tx-rubik mg-b-0">{ apiData[1] }<small className="tx-color-04 mg-l-10">{porcentagens[1]}%</small></h5>
                    </div>
                  </div>
                  <div className="col-6 mg-t-20">
                    <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">35 a 49 anos</p>
                    <div className="d-flex align-items-center">
                      <div className="wd-10 ht-10 rounded-circle bg-teal mg-r-5"></div>
                      <h5 className="tx-normal tx-rubik mg-b-0">{apiData[2]}<small className="tx-color-04 mg-l-10">{porcentagens[2]}%</small></h5>
                    </div>
                  </div>
                  <div className="col-6 mg-t-20">
                    <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Acima de 50 anos</p>
                    <div className="d-flex align-items-center">
                      <div className="wd-10 ht-10 rounded-circle bg-orange mg-r-5"></div>
                      <h5 className="tx-normal tx-rubik mg-b-0">{apiData[3]}<small className="tx-color-04 mg-l-10">{porcentagens[3]}%</small></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}

export default FaixaetariaIdade;