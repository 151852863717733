import React, { useEffect, useState } from "react"
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import { Icon } from 'leaflet'
import L from 'leaflet'
import "leaflet/dist/leaflet.css";
import "./mapa.css"

interface Position {
    id: number,
    name: string,
    latitude: number;
    longitude: number;
}

interface UpdateMapWithLocationProps {
position: Position | null;
}

const FitBounds: React.FC<{ positions: Position[] }> = ({ positions }) => {
    const map = useMap();
  
    useEffect(() => {
      if (positions.length > 0) {
        const bounds = L.latLngBounds(positions.map(p => [p.latitude, p.longitude]));
        map.fitBounds(bounds);
      }
    }, [positions, map]);
  
    return null;
  };
  

// const UpdateMap: React.FC<UpdateMapWithLocationProps> = ( { position } ) => {
//     const map = useMap();

//     useEffect(() => {
//         if(position){
//             map.setView([position.latitude, position.longitude], 13);
//         }
//     }, [position, map])

//     return null;
// };

// const RealTimeMap: React.FC = () => {
//     const [position, setPosition] = useState<Position | null>(null);

//     useEffect(() => {
//         if(navigator.geolocation){
//             const watchId = navigator.geolocation.watchPosition(
//             (position) => {
//                 const { latitude, longitude } = position.coords
//                 setPosition({latitude, longitude});
//             },
//             (error) => {
//                 console.log('Erro ao obter localização: ', error);
//             },
//             {
//                 enableHighAccuracy: true,
//                 maximumAge: 0,
//                 timeout: 0,
//             });
            
//             return () => navigator.geolocation.clearWatch(watchId);
//         }
//     }, []);

//     return (
//         <div>
//             <MapContainer center={ position ? [position.latitude, position.longitude] : [0,0]} zoom={1}>
//             <TileLayer
//                 attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//                 url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             />
//                 { position && (
//                     <>
//                         <Marker position={position ? [position.latitude, position.longitude] : [0,0]} icon={ new Icon({
//                             iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//                             iconUrl: require('leaflet/dist/images/marker-icon.png'),
//                             shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
//                         })}>
//                             <UpdateMap position={position}/>
                    
                    
//                             <Popup>
//                                 A pretty CSS3 popup. <br /> Easily customizable.
//                             </Popup>
//                         </Marker>
//                     </>
//                 )}
//             </MapContainer>
//         </div>
//     )
// }

const RealTimeMap: React.FC = () => {
    const [positions, setPositions] = useState<Position[]>([]);
  
    // Estabelece uma conexão WebSocket
    useEffect(() => {
      const socket = new WebSocket('ws://localhost:8080/');
  
      // Quando o WebSocket recebe dados (nova localização)
      socket.addEventListener("open", (ev) => {
            socket.onmessage = (event) => {
                const newPosition: Position = JSON.parse(event.data);
                setPositions((prevPositions) => [...prevPositions, newPosition]); // Adiciona a nova posição ao estado
            };

            // Envia a posição do usuário ao conectar (pode ser obtida via geolocalização)
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                const userPosition: Position = {
                id: Date.now(), // Usando o timestamp como ID único
                latitude,
                longitude,
                name: 'Novo Usuário', // Nome do usuário, você pode customizar
                };
                socket.send(JSON.stringify(userPosition)); // Envia a posição ao servidor
            });

            return () => {
                socket.close(); // Fecha a conexão ao desmontar o componente
            };
                
        })

       
     
    }, []);
  
    return (
      <MapContainer center={[-23.5558, -46.6396]} zoom={13} style={{ height: '100vh', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {positions.map((position) => (
          <Marker key={position.id} position={[position.latitude, position.longitude]}>
            <Popup>{position.name}</Popup>
          </Marker>
        ))}
        <FitBounds positions={positions} />
      </MapContainer>
    );
  };

export default RealTimeMap;