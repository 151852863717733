// Snackbar.tsx
import React, { useEffect, useState } from 'react';

interface SnackbarProps {
  message: string;
  open: boolean;
}

const Snackbar: React.FC<SnackbarProps> = ({ message, open }) => {
  const [visible, setVisible] = useState(open);

  useEffect(() => {
    setVisible(open);
  }, [open]);

  return (
    <div className={`snackbar ${visible ? 'show' : ''}`}>
      {message}
    </div>
  );
};

export default Snackbar;
