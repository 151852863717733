import React, { ReactNode, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Redux/store/store';
import api from '../Services/axios';
import axios from 'axios';
import localStorageAvailable, { getItem } from '../utils/localStorageAvailable';
import { isValidToken, setSession, decodeValidToken } from './utils';
import { User } from '../Types/user';
import { BallTriangle } from 'react-loader-spinner';
import Snackbar from '../utils/snackbar';

type AuthContextType = {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  method: string;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  logout: () => void;
  showLoading: () => void;
  hideLoading: () => void;
  showSnackbar: (message: string) => void; // Função para mostrar o Snackbar
};

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: any, action: any) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: action.payload.isInitialized,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      isInitialized: false,
      user: null,
    };
  }
  return state;
};

export const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false); 
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  // Função para mostrar o Snackbar
  const showSnackbar = (message: string) => {
    setSnackbar({ open: true, message });
    setTimeout(() => setSnackbar({ open: false, message: '' }), 3000); // Fecha após 3 segundos
  };

  // Funções para exibir e esconder o loading
  const showLoading = () => setIsLoading(true);
  const hideLoading = () => setIsLoading(false);

  // Inicialização
  const initialize = useCallback(async () => {
    showLoading();

    const usuario = await getItem('user') as string | null;
    const accessToken = getItem('accessToken') as string | null;

    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
      if(usuario)
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: true,
          isInitialized: true,
          user: JSON.parse(usuario),
        },
      });
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
    hideLoading();
  }, []);

  useEffect(() => {
    initialize();
  }, []);

  // Login
  const login = useCallback(async (email: string, password: string): Promise<void> => {
    showLoading();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');

    // const response = await api.post('/login', { email, password });
    const response = await api.post("/login", { email, password })
    const { user, token } = response.data;

    localStorage.setItem('accessToken', token);
    localStorage.setItem('user', JSON.stringify(user));
    setSession(token);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
    hideLoading();
  }, []);

  // Logout
  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    setSession(undefined);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // Registro
  const register = useCallback(async (email: string, password: string, firstName: string, lastName: string) => {
    showLoading();
    const response = await api.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });

    const { accessToken, user, jwt } = response.data;

    localStorage.setItem('accessToken', jwt);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
    hideLoading();
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      showLoading,
      hideLoading,
      showSnackbar,
    }),
    [state, login, logout]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 1000,
          }}
        >
          <BallTriangle color="#00BFFF" height={80} width={80} />
        </div>
      )}
       <Snackbar open={snackbar.open} message={snackbar.message} /> {/* Componente Snackbar */}
    </AuthContext.Provider>
  );
};
