import React, {useState, useEffect} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import api from "../../../Services/axios";
import { stringify } from 'querystring';

interface ApiData {
  id: number,
  nome: string,
  cidade: string,
  telefone: string,
  cpf: string
}

type EstadosContagem = {
  estado: string;
  qtde: number;
}

type ApiError = string | null;

// Registrando componentes do Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

  const Cidades: React.FC = () => {
    const [data, setData] = useState<EstadosContagem[] | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ApiError>(null);

    useEffect(() => {
      const fetchData = async () =>{
        try{
          setLoading(true);
          // const response = await Api.get<ApiData[]>("cadVisitantes/", {headers: { "Authorization": "Bearer " + localStorage.getItem("accessToken")}});
          const response = await api.get("/dashboard/estados", 
            { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }}
          )
          setLoading(false);
          setData(response.data);
        }catch (error){
          setError((error as Error).message);
          setLoading(false);
        }
      }

      fetchData();
    }, [])

    // const cidadesUnicas: CidadeContagem[] = Object.values((data ?? []).reduce<Record<string, CidadeContagem>>((acc, data) => {
    //   if(acc[data.cidade]){
    //     acc[data.cidade].qtde += 1;
    //   }else{
    //     acc[data.cidade] = { cidade: data.cidade, qtde: 1 };
    //   }
    //   return acc;
    // }, {}))

    if (loading) {
      return <div>Loading...</div>
    }

    return (    
      <div className="col-lg-2 col-md-4 mg-t-10">
        <div className="card ht-100p">
          <div className="card-header d-flex align-items-center justify-content-between pd-r-10">
            <h6 className="mg-b-0">Estados que mais nos visitam</h6>
            <div className="d-flex tx-16">
              <a href="" className="link-03 lh-0"></a>
              <a href="" className="link-03 lh-0 mg-l-5"></a>
            </div>
          </div>
          <ul className="list-group list-group-flush tx-13">
            { 
              data?.map((item, key) => {
                if(key >= 5){
                  return (<></>)
                }
                return (
                  <li key={key} className="list-group-item d-flex pd-sm-x-20">
                    <div  className="avatar d-none d-sm-block"><span className="avatar-initial rounded-circle bg-teal"></span></div>
                    <div  className="pd-sm-l-10">
                      <p  className="tx-medium mg-b-0"></p>
                      <small  className="tx-12 tx-semibold mg-b-0 ">{ item.estado }</small>
                      <br  />
                      <small  className='tx-light'>Mar 21, 2024</small>
                    </div>
                    <div   className="mg-l-auto text-end">
                      <p  className="tx-medium mg-b-0">{}</p>
                      <small   className="tx-12 mg-b-0 tx-bold">{ item.qtde }</small>
                      <br  />
                      <small  className="tx-12 tx-success mg-b-0">Visitantes</small>
                    </div>
                  </li>
                )
              })
            }
          </ul>
          </div>
        </div>
    )
}

export default Cidades;