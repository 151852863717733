import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Atividade } from "../../../Types/atividade";
import ListaSimples from "../../List/ListaSimples";
import api from "../../../Services/axios";
import { useAuthContext } from "../../../Auth/useAuthContext";
import InputText from "../../Input/InputText";


export interface PassageiroNew {
  viagem_id?: number; // É opcional porque nem todos os objetos têm essa chave
  nome: string;
  sobrenome: string;
  identificacao: string;
  cpf: string;
  nacionalidade: string | null;
  checkin: boolean;
  checkout: boolean;
  sexo: string;
  data_nascimento: string; // ISO string
  cidade: string | null;
  cidade_id: number | null;
  createdAt: string; // ISO string
  updatedAt: string; // ISO string
  id: number;
}
interface Viagem {
  id: number;
  visitante_id: number;
  municipio_id: number;
  tipo_turismo: string;
  nome_empresa: string | null;
  viajando_com: string; // Exemplo: 'Família', pode ser melhor tipado com um enum
  data_chegada: string; // ISO string
  data_partida: string; // ISO string
  conhece_municipio: boolean;
  acomodacao_tipo: string;
  acomodacao_nome: string;
  transporte_tipo: string;
  transporte_placa: string;
  emergencia_nome: string;
  emergencia_parentesco: string;
  emergencia_telefone: string;
  createdAt: string; // ISO string
  updatedAt: string; // ISO string
 // Visitante: VisitanteNew; // Relacionamento com a entidade Visitante
}

export interface DadosNew {
  passageiros: PassageiroNew[];
  viagem: Viagem; // Note que "pasageiros" parece conter um erro de digitação
}

interface Passageiro {
  id: string;
  nome: string;
  checkin: boolean;
  checkout: boolean;
  idViagem: number;
  sobrenome: string;
  identificacao: string;
  nacionalidade: string;
  emergencia_nome: string;
  emergencia_telefone: string;
  idAtividade?: string;
}

interface Empresa {
  _id: string;
  cnpj: string;
  rasao_social: string;
}

interface Usuario {
  _id: string;
  nome: string;
}

interface Veiculo {
  nome: string;
  destino: string;
  capacidade: string;
}

const AtividadeVisitantes: React.FC = () => {
  const location = useLocation();
  const { data } = location.state as { data: Atividade };
  const  {showSnackbar} = useAuthContext();
  const [pesquisa, setPesquisa] = useState('');
  const [newDados, setNewDados] = useState<Passageiro[]>([])
  const [trigger, setTrigger] = useState<boolean>(false);

  const [updatePassageiro, setUpdatePassageiro] = useState<Passageiro[]>(
    data.passageiros
  );
  
  const ShowSnackbar = (mensagem: string) => {
    if(mensagem)
      showSnackbar(mensagem);

  };

  useEffect(() => {
    const fetchData = async () => {
      const values = await api.get("/atividade/"+ data.id, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }})
      setNewDados(values.data.passengers)
    };

    fetchData();
  },[])

  useEffect(() => {
    if(newDados !== undefined){
      if(newDados.length !== 0){
        setUpdatePassageiro(newDados);
      }else{
        setUpdatePassageiro([])
      }
    }
  }, [newDados, trigger])

  const handleSearch = (text: string) => {
    setPesquisa(text);
  
    if (text === '') {
      // Se o campo de pesquisa estiver vazio, exibe todos os passageiros
      if(newDados !== undefined){
      if(newDados.length !== 0){
        setUpdatePassageiro(newDados);
      }
    }
      
    } else {
      // Filtra os passageiros pelo nome completo (nome + sobrenome)
      const filtered = updatePassageiro?.filter((userItem) =>
        `${userItem.nome}${userItem.sobrenome}`.toLowerCase().includes(text.toLowerCase())
      );
      setUpdatePassageiro(filtered);
    }
  };
  
  // Função para atualizar o checkin do passageiro
  function updatePassageiroCheckin(data: Atividade, passengerId: string) {
    const updatedPassageiros = data.passageiros.map((passageiro) =>
      passageiro._id === passengerId
        ? { ...passageiro, checkin: true }
        : passageiro
    );
    setUpdatePassageiro(updatedPassageiros);
    return updatedPassageiros;
  }


  // Função para atualizar o checkout do passageiro
  function updatePassageiroCheckout(data: Atividade, passengerId: string) {
    const updatedPassageiros = data.passageiros.map((passageiro) =>
      passageiro._id === passengerId
        ? { ...passageiro, checkout: true }
        : passageiro
    );
    setUpdatePassageiro(updatedPassageiros);
    return updatedPassageiros;
  }

  // Envia dados para realizar checkout do passageiro
  async function realizarCheckout(item: Passageiro) {
    let mensagem = "";
    if (item.checkin) {
      
      console.log("Efetuando checkin: ", item);

      mensagem ="Checkout efetuado com sucesso!";
      item.checkout = true;
      updatePassageiroCheckout(data, item.id);
    } else {
      console.log("Efetuando checkin: ", item);
      mensagem ="checkin efetuado com sucesso!";
      updatePassageiroCheckin(data, item.id);
    }

    //Enviar o pedido para o backend (descomentado caso necessário)
    await api.post("/atividade/checkout", {
      atividade: data.id,
      passageiro: item.id,
    }).then((response: any) => {
      console.log(response.data.message);
      ShowSnackbar(mensagem);
    }).catch((error: any) => {
      console.log(JSON.stringify(error))
      if(error.message)
        ShowSnackbar(error.message);
      console.log("Erro ao Ralizar Checkout.");
    });
  }

  async function removePassageiro(item: Passageiro) {
    await api.post("/atividade/removeacom", {
      atividade: data.id,
      passageiro: item.id,
    }).then((response: any) => {
      console.log(response.data.message);
    }).catch((error: any) => {
      if(error.response.data.message)
        ShowSnackbar(error.response.data.message);
      console.log("Erro ao Excluir Passageiro: "+ error.response.data.message);
    });

    const values = await api.get("/atividade/"+ data.id, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }})
    console.log("ultimo: "+JSON.stringify(values))
    setNewDados(values.data.passengers)
    setTrigger(!trigger);
  }

  return (
    <div className='containerbody2' style={{paddingTop: '20px',}}>
      <div>
        <div
          style={{
            
            fontSize: "15px",
            fontWeight: "bold",
            paddingTop: "10px",
            fontFamily: "Poppins-Regular",
            color: "#222222",
          }}
        >
          Conheça seus cliente!
        </div>
        <div
          style={{
            
            fontSize: "11px",
            fontWeight: "bold",
            fontFamily: "Poppins-Light",
            color: "#777777",
          }}
        >
          Ofereça a melhor experiência.
        </div>
        
      </div>

      <div style={{ paddingTop: "20px" }}>
      <InputText 
        id='pesquisar'
        type='text'
        title=''
        placeholder='Buscar passageiro'
        onchange={(e) => handleSearch(e.target.value)}
        value={pesquisa}
      />
      </div>

      <div
        style={{
          
          fontSize: "15px",
          fontWeight: "bold",
          paddingTop: "30px",
          fontFamily: "Poppins-Regular",
          color: "#222222",
        }}
      >
        <div>Quem irá efetuar Checkout?</div>
        {data && (
          <ListaSimples
            data={updatePassageiro}
            onItemClick={realizarCheckout}
            removePassageiro={removePassageiro}
          />
        )}
      </div>
    </div>
  );
};

export default AtividadeVisitantes;
