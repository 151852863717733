// src/pages/Dashboard.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaSearch, FaBell, FaReply , FaTimes    } from 'react-icons/fa';
import '../../css/dashforge.css';
import { useAuthContext } from '../../Auth/useAuthContext';

const Header: React.FC = () => {
  const { user } = useAuthContext();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Função para alternar a visibilidade do submenu
  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  // Função para alternar a visibilidade do menu lateral
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div style={{ width: '100%', padding: '20px' }}>
      <header className="navbar navbar-header navbar-header-fixed">
        <div className="mobile-only">
          <button style={{ margin: '5px', backgroundColor: 'transparent', border: 'none' }} onClick={toggleMenu}>
            <FaBars size={24} />
          </button>
        </div>

        {isMenuOpen && (
          <div className="sidebar-menu">

            <div className="sidebar-content">

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className="close-menu" onClick={toggleMenu}>
                  < FaTimes size={24} />
                </button>
              </div>
              

              <ul onClick={toggleMenu}>
                <li style={{fontWeight: 'bold'}}>
                  <Link to="/">Dashboard</Link>
                </li>
                <li style={{fontWeight: 'bold'}}>
                  <Link to="/mapa">Mapa</Link>
                </li>
                <li style={{fontWeight: 'bold'}}>
                  <Link to="/visitantes">Visitantes</Link>
                </li>
                <li style={{fontWeight: 'bold'}}>
                  <Link to="/Empresa/Atividades">Empresas</Link>
                </li>
                <li style={{fontWeight: 'bold'}}>
                  <Link to="/">Apps</Link>
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className="navbar-brand">
          <Link to="/" className="df-logo">sllips<span>dashboard</span></Link>
        </div>

        <div id="navbarMenu" className="navbar-menu-wrapper">
          <ul className="nav navbar-menu">
            <li className="nav-item with-sub ">
              {/* Adiciona o evento de clique para alternar o submenu */}
              {
                !['Colaborador','Marinha'].includes(user?.segmento?? '') ? 
                (
                  <Link to="#" className="nav-link" onClick={toggleSubMenu}>
                    Dashboard
                  </Link>
  
                ):
                (<></>)
              }
              {/* Exibe o submenu baseado no estado isSubMenuOpen */}
              {isSubMenuOpen && (
                <div
                  style={{
                    position: "absolute",
                    top: "20px",
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                    zIndex: 1,
                    paddingTop: 1,
                  }}
                >
                  <ul style={{ minWidth: "100px", listStyle: "none", padding: "10px", margin: 0 }}>
                    <li className="nav-sub-item">
                      <Link to="/visitantes" className="nav-sub-link">
                        Visitantes
                      </Link>
                    </li>
                    {/* Adicione outros links conforme necessário */}
                  </ul>
                </div>
              )}
            </li>
            <li className="nav-item">
              <Link to="/Empresa/Atividades" className="nav-link">
                Empresas
              </Link>
            </li>
            <li className="nav-item">
              
              {
                !['Colaborador'].includes(user?.segmento?? '') ? 
                (
                  <Link to="/mapa" className="nav-link">
                    Mapa
                  </Link>
  
                ):
                (<></>)
              }
            </li>
            <li className="nav-item with-sub">
              
              {
                !['Colaborador','Marinha'].includes(user?.segmento?? '') ? 
                (
                  <Link to="#" className="nav-link">
                    Apps
                   </Link>
  
                ):
                (<></>)
              }
              {/* Adicione submenus */}
            </li>
            {/* Adicione mais itens conforme necessário */}
          </ul>
        </div>

        <div className="navbar-right">
          <Link id="navbarSearch" to="#" className="search-link">
            <FaSearch />
          </Link>
          <div className="dropdown dropdown-notification">
            <button className="dropdown-link new-indicator" style={{ margin: '5px', backgroundColor: 'transparent', border: 'none' }}>
              <FaBell />
              <span>3</span>
            </button>
            {/* Adicione dropdown de notificações */}
          </div>
          <div className="dropdown dropdown-profile">
            <button style={{ margin: '5px', backgroundColor: 'transparent', border: 'none' }}>
              <div className="avatar avatar-sm">
                <img src="https://placehold.co/387" className="rounded-circle" alt="Profile" />
              </div>
            </button>
            {/* Adicione dropdown de perfil */}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
