export default function localStorageAvailable(): boolean {
  try {
    const key = '__some_random_key_you_are_not_going_to_use__';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
}

export function setItem<T>(key: string, value: T): void {
  if (localStorageAvailable()) {
    window.localStorage.setItem(key, JSON.stringify(value));
  } else {
    console.error('LocalStorage is not available');
  }
}

export function getItem<T>(key: string): T | null {
  if (localStorageAvailable()) {
    const item = window.localStorage.getItem(key);
    return item as T ;
  } else {
    console.error('LocalStorage is not available');
    return null;
  }
}

export function removeItem(key: string): void {
  if (localStorageAvailable()) {
    window.localStorage.removeItem(key);
  } else {
    console.error('LocalStorage is not available');
  }
}
