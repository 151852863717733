import React, { useState } from 'react';
import { FiActivity, FiBell, FiCamera, FiUser } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom'; 
import './Tabs.css'; 
import Atividades from '../../pages/AtividadePage';
import Notificacao from '../../pages/NotificacaoPage';
import QrCode from '../../pages/QrcodePage';

interface Tab {
  label: string;
  icon: JSX.Element;
  path: string; // Adiciona o caminho da rota
}

const Tabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const navigate = useNavigate(); 

  const tabs: Tab[] = [
    { label: 'Atividade', icon: <FiActivity size={24} />, path: '/Empresa/Atividades' },
    { label: 'Notificação', icon: <FiBell size={24} />, path: '/Empresa/Notificacao' },
    { label: 'QR Code', icon: <FiCamera size={24} />, path: '/Empresa/QRCode' },
    { label: 'Perfil', icon: <FiUser size={24} />, path: '/Empresa/Perfil' },
  ];

  const handleTabClick = (index: number, path: string) => {
    setActiveTab(index);
    navigate(path); 
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <Atividades />
      case 1:
        return <Notificacao />;
      case 2:
        return <QrCode />;
      case 3:
        return <div>Conteúdo do Perfil</div>;
      default:
        return null;
    }
  };

  return (
    <div style={{ display: 'block', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <div className="container-tabs">
          <div className="tabs">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab ${activeTab === index ? 'active' : ''}`}
                onClick={() => handleTabClick(index, tab.path)} // Chama a função handleTabClick
              >
                {tab.icon}
                <span>{tab.label}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="tab-content">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
