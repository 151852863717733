// src/components/Layout.tsx
import React from "react";
import Header from "./header";
import Main from "./main";
import { Outlet } from "react-router-dom";

const Layout: React.FC = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", }}>
      <Header />

      <Main>
        <Outlet />
      </Main>

      <footer
        style={{
          width: "100%",
          padding: "10px ",
          backgroundColor: "#f1f1f1",
          textAlign: "center",
          marginTop: "end"
        }}
      >
        <p
          style={{
            textAlign: "center",
            paddingTop: "10px",
            fontSize: "12px",
            fontFamily: "Poppins-Medium",
            color: "#222222",
          }}
        >
          © 2024 - Sllips ltda
        </p>
      </footer>
    </div>
  );
};

export default Layout;
