// utils.ts
import axios from '../Services/axios';

import { User } from '../Types/user'

// Tipagem para o token decodificado
interface DecodedToken {
  user: User
  exp: number;
  [key: string]: any; // Permite que o token contenha outras propriedades
}

// Tipagem para os props de token
interface TokenProps {
  token?: string;
  accessToken?: string | null;
}

// Função para decodificar o token JWT
function jwtDecode(token: string): DecodedToken {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// Função para verificar se o token é válido
export const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

// Função para decodificar o token se for válido
export const decodeValidToken = (accessToken: string): DecodedToken | false | User => {
  if (!accessToken) {
    return false;
  }

  return jwtDecode(accessToken);
};



// Função para iniciar um temporizador quando o token expirar
export const tokenExpired = (exp: number): void => {
  let expiredTimer: NodeJS.Timeout | null = null; // Inicializa como null
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;

  if (expiredTimer) {
    clearTimeout(expiredTimer); // Verifica se expiredTimer não é null
  }

  expiredTimer = setTimeout(() => {
    alert('Login expirado');
    localStorage.removeItem('accessToken');
    window.location.href = '/login';
  }, timeLeft);
};


// Função para configurar a sessão com o token
export const setSession = (accessToken?: string): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    
    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
