import React, { useMemo, useState, useEffect } from 'react';
import { Chart, ChartOptions, AxisOptions  } from 'react-charts';
import { curveNatural, curveBasis } from 'd3-shape'; 
import axios from 'axios';
import api from '../../../Services/axios';

interface DataPoint {
  primary: string;  // O eixo X será de tipo string (nomes dos meses)
  secondary: number; // O eixo Y será de tipo número (valores)
}

interface Series {
  label: string; // O nome de cada série (Série 1, Série 2, etc.)
  data: DataPoint[]; // Os pontos de dados para cada série
}

interface ApíData {
  data: Series[]
}

interface PerfilGeneroCount {
  homens: number,
  mulheres: number,
  criancas: number,
  outros: number
}

type ApiError = string | null;

const HistoricoVisitas: React.FC = () => {

// Definindo os dados do gráfico
// const data = React.useMemo(
//   () => [
//     {
//       label: 'Série 1',
//       data: [
//         { primary: 1, secondary: 2 },
//         { primary: 2, secondary: 3 },
//         { primary: 3, secondary: 5 },
//         { primary: 4, secondary: 4 },
//         { primary: 5, secondary: 7 },
//       ],
//     },
//     {
//       label: 'Série 2',
//       data: [
//         { primary: 1, secondary: 3 },
//         { primary: 2, secondary: 4 },
//         { primary: 3, secondary: 2 },
//         { primary: 4, secondary: 5 },
//         { primary: 5, secondary: 6 },
//       ],
//     },
//   ],
//   []
// );

// // Configurações do gráfico
// const options = React.useMemo<ChartOptions<any>>(
//   () => ({
//     data,
//     primaryAxis: {
//       getValue: (datum) => datum.primary,
//     },
//     secondaryAxes: [
//       {
//         getValue: (datum) => datum.secondary,
//         elementType: 'line',
//         curve: curveBasis
//       },
//     ],
//   }),
//   [data]
// );


  const [apiData, setData] = useState<ApíData>({data: []});
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<ApiError>(null)

  useEffect(() => {
    const fetchData = async () =>{
      try {
        setLoading(true);
        const value = await api.get("dashboard/historico-visitas", 
          { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }}
        )
        setData(value);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError((error as Error).message);
      }
    }

    fetchData();

  }, [])

  const data: Series[] = apiData['data'];

  // Configurando o eixo primário (eixo X)
  const primaryAxis = React.useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum: DataPoint) => datum.primary, // Mapeia o valor do eixo X
    }),
    []
  );

  // Configurando o eixo secundário (eixo Y)
  const secondaryAxis = React.useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum: DataPoint) => datum.secondary, // Mapeia o valor do eixo Y
      elementType: 'bar', // Definindo o gráfico de barras
      min: 0, // Definindo o mínimo valor do eixo Y
    }),
    []
  );

  // Função para personalizar o estilo de cada série com 4 cores diferentes
  const getSeriesStyle = React.useCallback((series: any) => {
    // Definindo as cores das séries
    const colors = ['#00cccc', '#fd7e14']; // Array com 4 cores
    return {
      fill: colors[series.index % colors.length], // Ciclando entre as cores definidas
    };
  }, []);

  // Opções do gráfico
  const options: ChartOptions<DataPoint> = {
    data,              // Dados do gráfico
    primaryAxis,       // Eixo X
    secondaryAxes: [secondaryAxis],  // Eixo Y
    tooltip: true,     // Ativa o tooltip
    getSeriesStyle,
  };

  // Função para processar os dados
  const result = data.reduce((acc, group) => {
    const total = group.data.reduce((sum, item) => sum + item.secondary, 0);
    if (group.label === 'Novo') acc.novo += total;
    if (group.label === 'Retorno') acc.retorno += total;
    return acc;
  }, { novo: 0, retorno: 0 });


  return (

    <div className="col-lg-8 col-xl-8">
      <div className="card">
        <div className="card-header bd-b-0 pd-t-20 pd-lg-t-25 pd-l-20 pd-lg-l-25 d-flex flex-column flex-sm-row align-items-sm-start justify-content-sm-between">
          <div>
            <h6 className="mg-b-5">Histórico de Visitas</h6>
            <p className="tx-12 tx-color-03 mg-b-0">Público ao qual os usuários pertenciam no intervalo de datas atual.</p>
          </div>
          <div className="btn-group mg-t-20 mg-sm-t-0">
            <button className="btn btn-xs btn-white btn-uppercase">Dia</button>
            <button className="btn btn-xs btn-white btn-uppercase">Semana</button>
            <button className="btn btn-xs btn-white btn-uppercase active">Mês</button>
          </div>
        </div>

        <div className="card-body pd-lg-25">
          <div className="row align-items-sm-end">
            {/* Dados lateral */}
            <div className="col-lg-5 col-xl-4 mg-t-30 mg-lg-t-0">
              <div className="row">
                
                <div className="col-sm-6 col-lg-12">
                  <div className="d-flex align-items-center justify-content-between mg-b-5">
                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Primeira Visita</h6>
                    {/* {<span className="tx-10 tx-color-04">65% comparado a</span>} */}
                  </div>
                  <div className="d-flex align-items-end justify-content-between mg-b-5">
                    <h5 className="tx-normal tx-rubik lh-2 mg-b-0">{result.novo}</h5>
                    {/* {<h6 className="tx-normal tx-rubik tx-color-03 lh-2 mg-b-0">20,000</h6>} */}
                  </div>
                  <div className="progress ht-4 mg-b-0 op-5">
                    <div className={`progress-bar bg-teal wd-${result.novo%100}p`} role="progressbar" aria-valuenow={65} aria-valuemin={0} aria-valuemax={100}></div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-12 mg-t-30 mg-sm-t-0 mg-lg-t-30">

                  <div className="d-flex align-items-center justify-content-between mg-b-5">
                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Segunda Visita</h6>
                    {/* <span className="tx-10 tx-color-04">45% comparado a</span> */}
                  </div>

                  <div className="d-flex justify-content-between mg-b-5">
                    <h5 className="tx-normal tx-rubik mg-b-0">{result.retorno}</h5>
                    {/* <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0"><small>250,000</small></h5> */}
                  </div>

                  <div className="progress ht-4 mg-b-0 op-5">
                    <div className={`progress-bar bg-teal wd-${result.retorno%100}p`} role="progressbar" aria-valuenow={65} aria-valuemin={0} aria-valuemax={100}></div>
                  </div>

                </div>



              </div>
              
            </div>

            <div className="col-lg-7 col-xl-8">


              <div style={{ width: '100%', height: '300px' }}>
                {data.length > 0 ?
                  (<Chart options={options} />)
                  :
                  (<p>Carregando Dados</p>)
                }
              </div>


            </div>

            

          </div>
        </div>
      </div>
    </div>
);
}

export default HistoricoVisitas;