import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../Auth/useAuthContext';
import { BallTriangle } from 'react-loader-spinner';

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isInitialized) {
        navigate('/login'); // Redireciona para a página de login
      }
    }, 3000000); // 3000 segundos

    return () => clearTimeout(timer); // Limpa o temporizador se o componente desmontar
  }, [isInitialized, navigate]);

  if (!isInitialized) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <BallTriangle color='#00bad1'/>
      </div>
    ); // Exibe o loading enquanto não está inicializado
  }

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
