import React from "react";
import { Route, useParams, useLocation, useNavigate } from "react-router-dom";
import { Atividade } from "../../../Types/atividade";
import ListaVerticalActive from "../../List/ListaVerticalActive";

interface Empresa {
  id: string;
  nome: string;
  tipo: string;
  cnpj: string;
}

interface DetalhesAtividadeProps {
  data: Empresa;
}

const RelatorioDeAtividades: React.FC = () => {
  const location = useLocation();
  const { data, nicho } = location.state as { data: Atividade, nicho:string };
  const navigate = useNavigate();

  return (
    <div className="containerbody2">
      <div
        style={{  cursor: "pointer", color: "#0168fa", padding: "10px",  fontFamily: "Poppins-Medium",fontSize:'12px' }}
        onClick={() => navigate(-1)}
      >
        <span>Voltar</span>
      </div>
      <div
        style={{
          margin: "1rem",
          fontSize: "15px",
          fontWeight: "bold",
          paddingTop: "10px",
          fontFamily: "Poppins-Regular",
          color:"#222222"
        }}
      >
        <div>Processo da atividade</div>

        {data && <ListaVerticalActive data={data.hora} type="Relatorio" nicho={nicho}/>}
      </div>
    </div>
  );
};

export default RelatorioDeAtividades;
