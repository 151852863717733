import api from '../Services/axios';


export const BuscaDadosAtividade_By_user_id = async ( id : string ) =>{

    const { data : res } = await api.get(`/atividades/${id}`, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }});
    
    if(res){
        try{
            return res;
        }catch(error){
            return "Falha ao listar Empresas tente mais tarde."
        }
    }else{
        console.log("Nenhum dados retornado")
    }

    return undefined;
    
}