import React from 'react';
import { FaExclamationCircle  } from 'react-icons/fa';

interface ConfirmationModalProps {
  message: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ message, isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <FaExclamationCircle size={58} color="#da3737" style={styles.icon} />
        <p style={styles.message}>{message}</p>
        <div style={styles.buttons}>
          <button style={styles.cancelButton} onClick={onCancel}>Cancelar</button>
          <button style={styles.confirmButton} onClick={onConfirm}>Confirmar</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    width: '90%',
    maxWidth: '400px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'center' as 'center',
  },
  icon: {
    marginBottom: '16px',
  },
  message: {
    fontSize: '16px',
    marginBottom: '24px',
    fontFamily: 'Poppins-Regular'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  cancelButton: {
    backgroundColor: '#f44336',
    color: '#fff',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  confirmButton: {
    backgroundColor: '#4caf50',
    color: '#fff',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
  },
};

export default ConfirmationModal;
