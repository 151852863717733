import React, { useEffect, useState } from "react";
import InputText from "../Input/InputText";
import "./styles.css";
import { useAuthContext } from "../../Auth/useAuthContext";
import { FaBuilding, FaBandAid, FaUsers } from "react-icons/fa";
import ListaAtividades from "../List/ListAtividades";
import { useNavigate, useLocation } from "react-router-dom";
import { BuscaDadosEmpresa_By_user_id } from "../../models/Empresa";
import api from "../../Services/axios"; 
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';


// interface Empresa {
//   id: string;
//   rasao_social: string;
//   nicho_mercado: string;
//   cnpj: string;
// }

interface EmpresaResponse {
  id: string;
  
  Empresa: [{
    id: string;
    rasao_social: string;
    nicho_mercado: string;
    atividade: string;
  }];
 
}

interface Empresa {
  id: string;
  rasao_social: string;
  nicho_mercado: string;
  cnpj: string;
  
}

interface Colaborador {
  id: number;
  nome: string;
  user_id: number;
  funcao: string;
  categoria: string;
  inscricao: string;
  telefone: string;
  cpf: string;
  habilitacao: string;
  habilitacao_marinheiro: string;
  createdAt: string;
  updatedAt: string;
  Empresas: Empresa[];
}

interface DataTable {
  id: number,
  empresa: string,
  lancha: string,
  piloto: string,
  duracao: string,
  qtdePessoas: number,
  ativa: string
}


const ListAtividadeEmpresa: React.FC = () => {
  const { user } = useAuthContext();
  const [pesquisa, setPesquisa] = useState("");
  const [totalClientes, setTotalClientes] = useState<number>();
  const [totalAtividade, setTotalAtividades] = useState<number>();
  const [empresa, setEmpresa] = useState<Empresa[]>([]);
  const [filteredEmpresas, setFilteredEmpresas] = useState<Empresa[]>([]);
  const [dataTable, setDataTable] = useState<DataTable[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate();
  const location = useLocation();

  const rows: GridRowsProp = dataTable;


  const columns: GridColDef[] = [
    { field: 'empresa', headerName: 'Empresa', width: 150 },
    { field: 'lancha', headerName: 'Lancha', width: 150 },
    { field: 'piloto', headerName: 'Piloto', width: 150 },
    { field: 'duracao', headerName: 'Duração', width: 150 },
    { field: 'qtdePessoas', headerName: 'Quantidade de Pessoas', width: 150 },
    { field: 'ativa', headerName: 'Em andamento?', width: 150 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const dataForTable = await api.get(`/dados-para-tabela/${user?.id}`, {headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")}}); 
        setDataTable(dataForTable.data);
        setLoading(false)
      } catch (error) {
        console.log(error);
      }

    }

    fetchData()
  },[])

  useEffect(() => {
    const fetchdata = async () => {
      if (user) {
        const dados = await BuscaDadosEmpresa_By_user_id(user.id)
      
        if (dados) {
          const novasEmpresas = dados.flatMap((item: Colaborador) => 
            item.Empresas.map((empresa) => ({
              id: empresa.id,
              rasao_social: empresa.rasao_social,
              nicho_mercado: empresa.nicho_mercado,
              cnpj: empresa.cnpj,
            }))
          );
          setEmpresa(novasEmpresas);
          setFilteredEmpresas(novasEmpresas);
        }
      }
    };
    fetchdata();
  }, [user]);


  const handleSearch = (text: string) => {
    setPesquisa(text);

    const filtered = empresa.filter((empresaItem) =>
      empresaItem.rasao_social.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredEmpresas(filtered);
  };

  const handleClick = (item: Empresa) => {
    const currentPath = location.pathname;
    navigate(`${currentPath}/Empresa`, {
      state: {
        id: item.id,
        empresa: item.rasao_social,
        nicho: item.nicho_mercado,
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const value = await api.get('/total-clientes', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }});
        setTotalClientes(value.data.message);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const value = await api.get('/total-atividades', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }});
        setTotalAtividades(value.data.message);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  },[])

  return (
    <div style={{ paddingTop: "20px" }}>
      <div className="containerbody">
        <InputText
          id="pesquisar"
          type="text"
          title=""
          placeholder="Buscar Empresa"
          onchange={(e) => handleSearch(e.target.value)}
          value={pesquisa}
        />
      </div>

      <div
        style={{
          fontSize: "15px",
          fontWeight: "bold",
          fontFamily: "Poppins-Regular",
          color: "#333333",
          justifyItems: "center",
        }}
      >
        Informações das Atividades
      </div>

      <div style={{gap:'1rem', display:'flex'}}>


        <div
          style={{
            border: "1px solid #c7c7c7",
            borderRadius: "25px",
            padding: "2px",
            paddingTop: "0px",
            paddingBottom: "0px",
            justifyItems: "center",
            marginTop: "2rem",
            width: "100%", 
            
          }}
        >
          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "15px",
              textAlign: "center",
             
            }}
          >
            <div>
              <FaBuilding size={34} style={styles.icon} />
              <div style={{ fontSize: "10px", fontFamily: "Poppins-semiBold" }}>
                Total de Empresas
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins-semiBold",
                  color: "#E61E4D",
                }}
              >
                {filteredEmpresas.length}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            border: "1px solid #c7c7c7",
            borderRadius: "25px",
            padding: "2px",
            paddingTop: "0px",
            paddingBottom: "0px",
            /*backgroundColor: '#0b92a7'*/ justifyItems: "center",
            marginTop: "2rem",
            width: "100%", 
          }}
        >
          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "15px",
              textAlign: "center",
            }}
          >
            <div>
              <FaBandAid size={34} style={styles.icon} />
              <div style={{ fontSize: "10px", fontFamily: "Poppins-semiBold" }}>
                Total de Atividades
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins-semiBold",
                  color: "#E61E4D",
                }}
              >
                {totalAtividade}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            border: "1px solid #c7c7c7",
            borderRadius: "25px",
            padding: "2px",
            paddingTop: "0px",
            paddingBottom: "0px",
            /*backgroundColor: '#0b92a7'*/ justifyItems: "center",
            marginTop: "2rem",
            width: "100%", 
          }}
        >
          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "15px",
              textAlign: "center",
            }}
          >
            <div>
              <FaUsers size={34} style={styles.icon} />
              <div style={{ fontSize: "10px", fontFamily: "Poppins-semiBold" }}>
                Total de Clientes
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins-semiBold",
                  color: "#E61E4D",
                }}
              >
                {totalClientes}
              </div>
            </div>
          </div>
        </div>

      </div>
      
      
      {
        user?.segmento === "Empresa" ?
        (
          <>
            <div
              style={{
                margin: "1rem",
                fontSize: "15px",
                fontWeight: "bold",
                paddingTop: "10px",
                fontFamily: "Poppins-Regular",
                color: "#222222",
              }}
            >
              Minhas empresas
            </div> 
          </>
        )
        :
        (
          <>
            <div
              style={{
                margin: "1rem",
                fontSize: "15px",
                fontWeight: "bold",
                paddingTop: "10px",
                fontFamily: "Poppins-Regular",
                color: "#222222",
              }}
            >
              Empresas que estou cadastrado
            </div>
          </>
        )
      }

      {filteredEmpresas && (
        <ListaAtividades
          atividades={filteredEmpresas}
          onItemClick={handleClick}
        />
      )}

      {
        user?.segmento === "Empresa" ?
        (
          <>
            {
              loading ? 
              (
                <><h2>Carregando dados da tabela</h2></>
              )
              :
              (
                <>
                  <div
                    style={{
                      margin: "1rem",
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingTop: "10px",
                      fontFamily: "Poppins-Regular",
                      color: "#222222",
                    }}
                  >
                    Dados das atividades
                  </div> 
                  <DataGrid 
                    rows={rows} 
                    columns={columns}
                    sx={{
                      "& .MuiDataGrid-root": {
                        fontSize: "16px", // Tamanho da fonte geral
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        fontSize: "18px", // Tamanho da fonte do cabeçalho
                        fontWeight: "bold", // Opcional: Negrito no cabeçalho
                      },
                      "& .MuiDataGrid-cell": {
                        fontSize: "16px", // Tamanho da fonte das células
                      },
                    }}
                  />
                </>
              )
            }
          </>
        )
        :
        (
          <></>
        )
      }
      
    </div>
  );
};

const styles = {
  icon: {
    color: "#222222",
    size: "20rem",
  },
};

export default ListAtividadeEmpresa;
