export function FormatMonth(data : string){
    const nomesMeses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ];

    const aux = new Date(data);
    const ano = aux.getFullYear();
    return `${nomesMeses[aux.getMonth()]} - ${ano}`;
    
}

//formata a Hora no formato: 10:10
export function FormatTime(data: string): string {
    const aux = new Date(data);

    const horas = aux.getHours().toString().padStart(2, "0");
    const minutos = aux.getMinutes().toString().padStart(2, "0");

    return `${horas}:${minutos}`;
}
